<template>
  <div>
    <!-- <header class="main-header">
      <div class="nav-menu-btn">
        <div class="nav-menu-btn-line"></div>
      </div>
      <div class="lang-select-btn">en</div>
    </header> -->
    <router-view />
    <footer class="main-footer">
      <div class="container">
        <div class="main-footer-wrapper">
          <div class="footer-nav">
            <a href="http://instagram.com/pavel.tigiev/" target="_blank" class="footer-nav-link">instagram</a>
            <a href="http://youtube.com/@paveltigiev/" class="footer-nav-link" target="_blank">youtube</a>
          </div>
          <div class="madeby">
            Made by Pavel Tigiev
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
		const cursor = document.createElement('div');
		cursor.classList.add('cursor');
		document.body.prepend(cursor);

		document.addEventListener('mousemove', (e) => {
			cursor.style.left = `${e.pageX}px`;
			cursor.style.top = `${e.pageY - window.scrollY}px`;
		});
    this.addCursorEffect();
  },
  methods: {
    addCursorEffect() {
      const links = document.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('mouseenter', this.handleMouseEnter);
        link.addEventListener('mouseleave', this.handleMouseLeave);
      });
    },
    handleMouseEnter() {
      document.querySelector('.cursor').classList.add('hover');
    },
    handleMouseLeave() {
      document.querySelector('.cursor').classList.remove('hover');
    }
  },
};
</script>

<style lang="scss">
</style>
