<template>
  <div class="contactBlock" id="contact">
    <div class="container">
      <div class="contact-title">

        <h2 class="contact-heading main-title">
          text <span class="outlined">→</span> <br>
          me
        </h2>

        <p class="contact-desciption">Tell me a little bit about your project and how I can help you</p>

        <!-- <a href="#contact" class="order-button mt80">
          <div class="button-text tc-black">Go to Order</div>
          <div class="button-text tc-black">→</div>
        </a> -->
      </div>

      <div class="contact-items">

        <p class="contact-link-title">email me at</p>
        <a href="mailto:paulo.tigiev@gmail.com" class="contact-link">paulo.tigiev@gmail.com</a>
        <p class="contact-link-title">or text me on my telegram</p>
        <a href="https://t.me/pavel_tigiev" class="contact-link" target="_blank">@pavel_tigiev</a>
        <!-- <p class="contact-link-title">or email us at</p>
        <a href="mailto:paulo.tigiev@gmail.com" class="contact-link">werwerfwerwe</a> -->
      </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .contactBlock {
    // display: flex;
    // height: calc(100vh);
    margin: 100px 0;
    .container {
      display: flex;
      flex-direction: row;
      align-items: start;
    }
    .contact-heading {
      margin: 0 0 40px;
    }
    .contact-desciption {
      max-width: 28ch;
      margin-bottom: 34px;
      font-size: 18px;
      line-height: 1.5em;
      font-weight: 300;
    }
    .contact-title {
      width: 25%;
      margin-right: 13%;
    }
    .contact-items {
      width: 62%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      .contact-link-title {
        margin-bottom: 0px;
        font-size: 24px;
        line-height: 1em;
        font-weight: 600;
      }
      .contact-link {
        transition: color .2s;
        font-family: 'Montreux Classic',sans-serif;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        margin-bottom: 40px;

        &:hover {
          color: #f4c644;
        }
      }
    }
  }
</style>