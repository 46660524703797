<template>
  <div class="heroBlock">
    <div class="container">
      <div class="hero">
        <h1 class="hero-heading main-title">
          <span class="outlined">My name is </span>Pavel Tigiev.<br/>
          <span class="outlined">I'm </span>developer<span class="outlined">,</span>
          project manager
          <span class="outlined">and</span>
          videomaker<span class="outlined">.</span>
        </h1>
        <div class="hero-block">
          <p class="hero-decription">I have more than 10 years of experience in IT project development and management. Implemented more than 100 projects. I also do YouTube production.</p>
          <a href="#contact" class="order-button">
            <div class="button-text">Contact me</div>
            <div class="button-text tc-black">→</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/_variables.scss";
  .hero {
    height: calc(100vh - 100px);
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    .hero-heading {
      width: 860px;
    }
    .hero-block {
      width: 340px;
      .hero-decription {
        margin-top: -4px;
      }
      .order-button {
        margin-top: 18px;
      }
    }
  }

</style>