<template>
  <Carousel v-bind="settings" :breakpoints="breakpoints" class="projectsSlider">
    <Slide v-for="imagePath in items" :key="imagePath">
      <div class="carousel__item">
        <img :src="imagePath" alt="">
      </div>
    </Slide>
    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'ProjectsSlider',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    items: [
      '/p1.png',
      '/p2.png',
      '/p3.png',
    ],
    settings: {
      itemsToShow: 1,
      snapAlign: 'start',
      wrapAround: true,
    },
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 2.5,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3.5,
        snapAlign: 'start',
      },
    },
  }),
});
</script>

<style lang="scss">
  .projectsSlider {
    margin-bottom: 100px;
    .carousel__slide {
      padding-right: 20px;
    }
  }
  .carousel__prev, .carousel__next {
    display: none;
  }
</style>