<template>
  <div class="rulesBlock">
    <div class="container">
      <div class="rules">
        <div class="rule-row">
          <div class="rule-title">Full Day Work</div>
          <div class="rule-arr">←</div>
        </div>
        <div class="rule-row">
          <div class="rule-title">Around The World</div>
          <div class="rule-arr">←</div>
        </div>
        <div class="rule-row">
          <div class="rule-title">A Deal On Upwork</div>
          <div class="rule-arr">←</div>
        </div>
        <div class="rule-row">
          <div class="rule-title">Best For Startups</div>
          <div class="rule-arr">←</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/_variables.scss";
  .rulesBlock {
    background: $primary-color;
    padding: 120px 0;
    font-size: 120px;
    line-height: 144px;
    letter-spacing: -3px;
    font-weight: 700;
    color: #000;

    .rules {
      display: flex;
      flex-direction: column;
      .rule-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .rule-title {
          
        }
        .rule-arr {
          
        }
      }
    }
  }
</style>