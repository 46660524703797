import { createRouter, createWebHistory } from 'vue-router'
import HomePage from './views/HomePage'
import AboutPage from './views/AboutPage'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'HomePage',
      component: HomePage
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage
    }
  ]
})

export default router
