<template>
  <div class="servicesBlock">
    <div class="container">
      <div class="services-title">
        <h2 class="services-heading main-title">
          <span class="outlined">What <br> I can</span> offer <span class="outlined">you</span>
        </h2>
        <a href="#contact" class="order-button mt80">
          <div class="button-text tc-black">Go to Order</div>
          <div class="button-text tc-black">→</div>
        </a>
      </div>

      <div class="services-grid">

        <div class="services-grid-item">
          <h4 class="services-grid-item-heading">Frontend development</h4>
          <p class="services-grid-item-text">
            Writing a modern, adaptive front end for your website or application in Vuejs.js based on your design or an interface I created. 
          </p>
          <img src="@/assets/img/coding-icon.svg" loading="lazy" alt="Frontend development" height="" class="services-grid-item-icon">
        </div>
        <div class="services-grid-item">
          <h4 class="services-grid-item-heading">Project Management</h4>
          <p class="services-grid-item-text">Managing a development team of 5 to 20 people using flexible management methodologies. </p>
          <img src="@/assets/img/pm-icon.svg" loading="lazy" alt="Project Management" height="" class="services-grid-item-icon">
        </div>
        <div class="services-grid-item">
          <h4 class="services-grid-item-heading">Creating an MVP</h4>
          <p class="services-grid-item-text">Creating a minimum viable product for startups and large companies in a minimum amount of time. </p>
          <img src="@/assets/img/mvp-icon.svg" loading="lazy" alt="Creating an MVP" height="" class="services-grid-item-icon">
        </div>
        <div class="services-grid-item">
          <h4 class="services-grid-item-heading">Video making</h4>
          <p class="services-grid-item-text">Creating video content, from gathering information and writing a script to shooting and editing in 4k.</p>
          <img src="@/assets/img/video-icon.svg" loading="lazy" alt="Video making" height="" class="services-grid-item-icon">
        </div>
      </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .servicesBlock {
    // display: flex;
    // height: calc(100vh);
    margin: 100px 0;
    .container {
      display: flex;
      flex-direction: row;
      align-items: start;
    }
    .services-title {
      width: 25%;
      margin-right: 13%;
    }
    .services-grid {
      width: 62%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      grid-auto-columns: 1fr;
      grid-template-rows: auto auto;
      .services-grid-item {
        // margin: 1% 0 0 1%;
        display: flex;
        grid-column-start: span 1;
        flex: 35%;
        height: 260px;
        padding: 40px;
        align-items: flex-start;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 64px;
        background-color: rgba(249,246,224,.05);

        .services-grid-item-heading {
          font-size: 22px;
          line-height: 1em;
          margin-top: 0;
          margin-bottom: 24px;
          font-weight: 600;
        }
        .services-grid-item-text {
          font-size: 16px;
          line-height: 1.5em;
          flex: 1;
        }
        .services-grid-item-icon {
          max-width: 64px;
          max-height: 64px;
        }
      }
    }
  }
</style>