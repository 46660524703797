<template>
  <div class="offerBlock">
    <div class="container">
      <div class="offer">
        <h2 class="offer-heading main-title">
          <span class="outlined">Are you ready<br></span>
          to bring your ideas to life?
        </h2>
        <p class="offer-decription">
          From ideation to launch, I'll work closely with you to ensure that every detail is perfect. 
          I'll help you <span class="havy-text">refine your vision</span>, develop a 
          <span class="havy-text">solid plan</span>, and <span class="havy-text">execute it flawlessly</span>.<br/>
          So why wait?
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/css/_variables.scss";

  .offerBlock {
    display: flex;
    height: calc(100vh);
    align-items: center;
    background-image: url('@/assets/img/offer_bg.svg');
    background-position: 50% 30%;
    background-size: 700px;
    background-repeat: no-repeat;
    text-align: center;

    .offer {
      width: 700px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;

      .offer-decription {
        width: 90%;
        font-size: 18px;
        line-height: 1.5em;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

  }
</style>