<template>
  <div class="homePage">
    <HeroBlock />
    <ProjectsSlider />
    <OfferBlock />
    <ServicesBlock />
    <RulesBlock />
    <ContactBlock />
  </div>
</template>

<script>
import HeroBlock from '@/components/HeroBlock.vue'
import ProjectsSlider from '@/components/ProjectsSlider.vue'
import OfferBlock from '@/components/OfferBlock.vue'
import ServicesBlock from '@/components/ServicesBlock.vue'
import ContactBlock from '@/components/ContactBlock.vue'
import RulesBlock from '@/components/RulesBlock.vue'

export default {
  components: { HeroBlock, ProjectsSlider, OfferBlock, ServicesBlock, ContactBlock, RulesBlock },
  data() {
    return {
    };
  },
  mounted() {
  },
};
</script>

<style>
</style>